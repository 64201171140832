<template>
    <v-container fluid>
        <v-form @submit.prevent="getDepartures('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'departure_auto.create', params: {type_receipt: $route.params.id}}"
                   color="indigo" dark fab
                   fixed top right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'departure'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('search_departure') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="6" class="text-right">
                                <v-btn :to="{name: 'departure_auto.create', params: {type_receipt: $route.params.id}}" color="indigo" dark>{{$t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="container" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="container" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-focus-field-horizontal"
                                                  :label="$t('container')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text class="px-0">
                <v-data-table :headers="filteredHeaders" :items="departures" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalDepartures"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ $moment(item.created_at).format('DD.MM.YYYY') }}
                    </template>
                    <template v-slot:item.arrival_time="{ item }">
                        {{ $moment(item.arrival_time).format('HH:mm') }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editApplication(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteApplication(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'

export default {
    name: "Departures",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            fab: false,
            phoneRaw: null,
            container: null,
            car_number: null,
            departures: [],
            departureItems: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalDepartures: 0,
            options: {},
            loading: false,
            headers: [
                {
                    text: this.$t('container'),
                    sortable: false,
                    value: "container_number"
                },
                {
                    text: this.$t('car_number'),
                    sortable: false,
                    value: "car_number"
                },
                {
                    text: this.$t('driver_name'),
                    sortable: false,
                    value: "driver_name"
                },
                {
                    text: this.$t('driver_phone'),
                    sortable: false,
                    value: "driver_phone"
                },
                {
                    text: this.$t('status'),
                    sortable: false,
                    value: "status",
                },
                {
                    text: this.$t('in_detail'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    watch: {
        options: {
            handler() {
                this.getDepartures()
            },
            deep: false
        }
    },
    methods: {
        editApplication(item) {
            this.$router.push({
                name: 'departure_auto.edit',
                params: {
                    type_receipt: this.$route.params.id,
                    id: item.uuid
                }
            })
        },
        async deleteApplication(item) {
            if (confirm(this.$t('delete_departure'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`partner/application_departure/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('departure_has_been_deleted'))
                        this.getDepartures()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('departure_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getDepartures(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.container) {
                params.container = this.container;
            }
            if (this.phone) {
                params.phone = this.phone;
            }
            if (this.wagon_number) {
                params.wagon_number = this.wagon_number;
            }

            params.type_receipt = this.$route.params.id


            await this.$http
                .get("partner/application_departure", {
                    params: params,
                })
                .then(res => {
                    this.departures = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalDepartures = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.departures = []
                    this.totalDepartures = 0
                    this.$toastr.error(this.$t('failed_to_get_list_departures'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
